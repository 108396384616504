import React from 'react'
import { Route, Switch } from 'react-router-dom';

import OnboardingWrapper from 'src/navigators/Wrappers/OnboardingWrapper'
import ErrorBoundary from 'src/navigators/Wrappers/ErrorBoundary';
import Login from 'src/components/Login';

const Anonymous = function () {
    return (
        <OnboardingWrapper>
            <ErrorBoundary>
                <Switch>
                    <Route path='/login' component={Login} />
                    <Route component={Login} />
                </Switch>
            </ErrorBoundary>
        </OnboardingWrapper>
    )
}

export default Anonymous;