import React, { useEffect, Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import ErrorBoundary from 'src/navigators/Wrappers/ErrorBoundary';
import Header from './Header';
import Splash from './Splash';

import ReviewRecordingSnippets from 'src/components/ReviewRecordingSnippets'
import { QueryRenderer, graphql } from 'react-relay';
import { useRelayFramework } from 'src/providers/RelayProvider';
import './style.scss';
import Sidebar from './SideBar';


const AuthenticatedQuery = graphql`
    query AuthenticatedQuery {
        currentUser {
            id
            email
            isEmailVerified
            isPaid
            ...Header_user
        }
    }
`;

const AuthenticatedRender = function({currentUser}){
    const { email, isEmailVerified } = currentUser;
    useEffect(()=>{
        Sentry.configureScope(function(scope) {
            scope.setUser({email});
        });
    }, [])

    if(!isEmailVerified){
        return (
            <OnboardingWrapper>
                <ErrorBoundary>
                    <IntegrationEnabler />
                    <EmailVerificationWall/>
                </ErrorBoundary>
            </OnboardingWrapper>
        )
    }

    return (
        <Fragment>
            <Header currentUser={currentUser}/>
            <Sidebar/>
            <ErrorBoundary>
                <div className={'content-wrapper'}>
                    <Switch>
                        <Redirect exact from="/" to="/review-recording-snippets"/>
                        <Route path="/review-recording-snippets" component={ReviewRecordingSnippets}/>
                        <Route path="/quality-analysis" component={()=> {
                            window.location.replace('https://tfx-review.firebaseapp.com/review')
                            return null;
                        }}/>
                    </Switch>
                </div>
            </ErrorBoundary>
        </Fragment>
    )
}


export default function Authenticated(){
    const { environment } = useRelayFramework();
    /**
     * Clear store when user signs out
     */
    useEffect(()=>{
        return () => {
            environment.getStore().getSource().clear();
        }
    },[])

    return (
        <QueryRenderer
            environment={environment}
            query={AuthenticatedQuery}
            render={({props}) => {
                if(props){
                    return <AuthenticatedRender {...props}/>
                } else {
                    return <Splash/>
                }
            }}
        />
    );
};
