import React from 'react'
import { withRouter } from 'react-router-dom'
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            pathname: null,
            hasError: false 
        };
    }
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    static getDerivedStateFromProps(props, state){
        const {pathname} = props.history.location;
        if(pathname != state.pathname){
            return {pathname, hasError: false}
        } else {
            return state
        }
    }

    componentDidCatch(error, errorInfo) {
        console.log(error)
        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({eventId});
        });
    }


    render() {
        if (this.state.hasError) {
            return (
                <div className='full-screen-error-message'>
                    <h1>Something went wrong.</h1>
                    <button onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Report a bug</button>
                </div>
            );
        }
        return this.props.children; 
    }
}

export default withRouter(ErrorBoundary);



