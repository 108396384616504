import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useRelayFramework } from "src/providers/RelayProvider";
import { commitMutation, graphql, createFragmentContainer } from 'react-relay';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useAuthenticator } from 'src/providers/AuthenticatorProvider';

const LogoutUserMutation = graphql`
    mutation Header_LogoutUserMutation($input: LogoutUserMutationInput!) {
		logoutUser(input:$input){
			revoked
		}
    }
`;

const headerBackground = window.origin.includes('admin') ? 'darkorchid' : 'white'

const useStyles = makeStyles(theme => ({
	appBar: {
		background:headerBackground,
		zIndex: theme.zIndex.drawer + 1,
		height: '50px',
	},
	appToolbar:{
		height: '50px !important',
		minHeight: '50px !important'
	},
	appTitle:{
		display:'flex',
		flexGrow: 1,
		marginLeft: theme.spacing(3),
		alignItems: 'center'
	},

	appLogoLink:{
		display: 'flex'
	},

	appLogo: {
		height: '20px',
		width: 'auto',
	},

	rightMenuWrapper:{
		marginTop: '36px',
		right:0
	}
}));



function Header({currentUser}){
	const { firstName, email } = currentUser;
	const [anchorEl, setAnchorEl] = useState(null);
	const { environment } = useRelayFramework();
	const authenticator = useAuthenticator();
	const history = useHistory();
	const classes = useStyles()

	const handleLogout = () => {
		commitMutation(environment, {
			mutation: LogoutUserMutation,
			variables: {
				input: {}
			},
			onError(){
				alert("Something went wrong!")
			},
			onCompleted(){
				authenticator.setIsLoggedIn(false);
			}
		});
	}

	return (
		<AppBar position="fixed" className={classes.appBar}>
			<Toolbar disableGutters={true} className={classes.appToolbar}>

			<div className={classes.appTitle}>
                <Link to="/" className={classes.appLogoLink} >
				    <img className={classes.appLogo} src={require("assets/images/small-logo-blue.png")} alt="No image"/>
                </Link>
			</div>

			<div style={{color:'grey', fontFamily:'nunito', marginTop:'2px'}}>{firstName || email}</div>
			<IconButton
				aria-label="account of current user"
				aria-controls="menu-appbar"
				aria-haspopup="true"
				onClick={(event) => setAnchorEl(event.currentTarget)}
				color="primary">
				<AccountCircle />
			</IconButton>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				PopoverClasses={{paper: classes.rightMenuWrapper}}
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}>
				<MenuItem  onClick={handleLogout}>Sign Out</MenuItem>
			</Menu>
			</Toolbar>
		</AppBar>
	)

}

export default createFragmentContainer(Header, {
    currentUser: graphql`
        fragment Header_user on CurrentUserNode {
            id
            email
			firstName
        }
    `,
});